import { createGlobalStyle } from "styled-components";
import { media } from "er.js.utils";

export const GlobalStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        font-size: 100%;
        line-height: normal;
        vertical-align: baseline;
    }

    * {
        box-sizing: border-box !important;
        outline: none;
    }

    html {
        scroll-behavior: smooth;
    }

    body {
        margin: 0 auto;
        color: ${({ theme }) => theme.colors.black};
        background-color: ${({ theme }) => theme.colors.white};
        font-family: '${({ theme }) => theme.font.family.arabic}', sans-serif;
        font-size: 100%;
        font-weight: ${({ theme }) => theme.font.weight.regular};
        direction: rtl;
    }

    h1, h2, h3 h4, h5, h6 {
        font-weight:  ${({ theme }) => theme.font.weight.medium};
    }

    h1 {
        font-size: ${({ theme }) => theme.font.size.lg};

        ${media("md")} {
            font-size: ${({ theme }) => theme.font.size.xl};
        }

        ${media("lg")} {
            font-size: ${({ theme }) => theme.font.size.xxl};
        }
    }

    h2 {
        font-size: ${({ theme }) => theme.font.size.xl};
    }

    h3 {
        font-size: ${({ theme }) => theme.font.size.lg};
    }

    h4 {
        font-size: ${({ theme }) => theme.font.size.md};
    }

    h5 {
        font-size: ${({ theme }) => theme.font.size.md};
    }

    h6 {
        font-size: ${({ theme }) => theme.font.size.md};
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    input,
    textarea,
    select,
    button {
        font-family: ${({ theme }) => theme.font.family.arabic}, Tahoma, sans-serif;
        font-size: ${({ theme }) => theme.font.size.md};

        ::placeholder{
            color: ${({ theme }) => theme.colors.gray.medium};
        }
    }

    b, strong {
        font-weight: ${({ theme }) => theme.font.weight.bold};
    }

    small {
        font-size: ${({ theme }) => theme.font.size.sm};
    }

    svg {
        display: inline-block;
        vertical-align: middle;
    }

    ul {
        list-style: none;
    }
`;
