import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    firstName: "",
    familyName: "",
    gender: "",
    picture: "",
    token: "",
    expiry: "",
    claims: [],
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        userAuthenticated(state, action) {
            // https://redux-toolkit.js.org/usage/immer-reducers#resetting-and-replacing-state
            return action.payload;
        },
        userLoggedOut(state) {
            return initialState;
        },
    },
});

// selector to select the user state
export const selectToken = (state) => state.user.token;
export const selectTokenExpiry = (state) => state.user.expiry;
export const selectUserClaims = (state) => state.user.claims;
export const selectUser = (state) => state.user;

// export actions
export const { userAuthenticated, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
