import { store } from "../app/store";
import jwt_decode from "jwt-decode";
import { userAuthenticated } from "../features/user/userSlice";
import { CLAIM_TYPES, ERROR_URL } from "./settings";
//import { createAsyncThunk } from "@reduxjs/toolkit";
//import { api } from "../services/api";

// update the user state based on the response recceived from authentication/refresh token
export const updateUserState = (payload) => {
    // https://www.npmjs.com/package/jwt-decode
    const token = jwt_decode(payload.token);
    const claims = payload.claims;

    store.dispatch(
        userAuthenticated({
            firstName: token.given_name,
            familyName: token.family_name,
            gender: token.gender,
            picture: token.picture,
            token: payload.token,
            expiry: token.exp,
            claims: claims,
        })
    );
};

// this checks if the user has one of the required claims
export const userHasClaims = (allowClaims, userClaims) => {
    return allowClaims.some((c) => userClaims.includes(CLAIM_TYPES[c]));
};

// this checks if the user doesn't have one of the required claims
export const userDoesntHaveClaims = (denyClaims, userClaims) => {
    return !denyClaims.some((c) => userClaims.includes(CLAIM_TYPES[c]));
};

// get image thumbnail version
export const getImageThumbnailName = (imageName) => {
    return `${imageName.slice(0, imageName.lastIndexOf("."))}_s${imageName.slice(
        imageName.lastIndexOf(".")
    )}`;
};

// download image with custom http request
export const downloadImage = (link, userToken, callback) => {
    let request = new XMLHttpRequest();
    request.addEventListener(
        "load",
        (e) => {
            if (e.target.status === 200) {
                let imageURL = URL.createObjectURL(e.target.response);
                callback(imageURL);
            }
        },
        false
    );
    request.responseType = "blob";
    request.open("GET", link);
    request.setRequestHeader("authorization", `Bearer ${userToken}`);
    request.send();
};

// send error report 
export const reportError = async (errorType, errorInfo) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch

    await fetch(ERROR_URL, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            errorType: errorType.message,
            errorInfo: errorInfo.componentStack
        })
    });
};

// convert guid to string and remove the hyphen
//export const normalizeGuid = (guid) => {
//    return `${guid}`.replaceAll("-", "");
//};

//export const refreshToken = (payload) => (dispatch, getState) => {
//    dispatch(api.endpoints.refreshToken.initiate(payload))
//        .unwrap()
//        .then((payload) => {
//            // save the new token and claims to the state
//            decodeAndSaveToken(payload);
//        })
//        .catch((error) => {
//            // logout the user if the refresh token fails
//            if (error.status === 400) {
//                dispatch(userLoggedOut());
//            }
//        });
//};

//export const refreshToken = createAsyncThunk(
//    "user/refreshToken",
//    async (payload, { dispatch, getState }) => {
//        dispatch(api.endpoints.refreshToken.initiate(payload))
//            .unwrap()
//            .then(payload => {
//                // save the new token and claims to the state
//                decodeAndSaveToken(payload);
//            })
//            .catch((error) => {
//                // logout the user if the refresh token fails
//                if (error.status === 400) {
//                    dispatch(userLoggedOut());
//                }
//            });
//    }
//);
