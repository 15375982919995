import { FiX, FiMenu, FiAlertOctagon, FiAlertCircle, FiCheck, FiChevronDown } from "react-icons/fi";

const COLORS = {
    primary: "#1DE758", // green
    complementary: "#6A1B9A", // violet
    gray: {
        dark: "#808285",
        medium: "#D4D1D1",
        light: "#E6E6E6",
        lightest: "#F2F2F2",
    },
    black: "#0A0A0A",
    white: "#FFFFFF",
};

const FONT = {
    family: {
        arabic: "Tajawal",
    },
    weight: {
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
    },
    size: {
        xxxl: "2em",
        xxl: "1.6em",
        xl: "1.35em",
        lg: "1.2em",
        md: "1em",
        sm: ".85em",
        xs: ".7em",
        xxs: ".6em",
    },
};

const SHADOW = "0 4px 8px 0 rgba(0, 0, 0, 0.2)";

export const theme = {
    colors: COLORS,

    font: FONT,

    alert: {
        icon: <FiChevronDown />,
        error: {
            color: COLORS.white,
            backcolor: COLORS.complementary,
            icon: <FiAlertOctagon />,
        },
        success: {
            color: COLORS.white,
            backcolor: COLORS.primary,
            icon: <FiCheck />,
        },
    },

    loadingbar: {
        color: COLORS.complementary,
        backcolor: "transparent",
    },

    button: {
        basic: {
            color: COLORS.white,
            backcolor: COLORS.gray.medium,
        },
        primary: {
            color: COLORS.white,
            backcolor: COLORS.primary,
            shadow: SHADOW,
        },
        complementary: {
            color: COLORS.white,
            backcolor: COLORS.complementary,
            hoverbackcolor: COLORS.gray.medium,
        },
        secondary: {
            hovercolor: COLORS.white,
            color: COLORS.gray.dark,
            hoverbackcolor: COLORS.gray.dark,
            backcolor: COLORS.gray.medium,
        },
        dark: {
            color: COLORS.white,
            // hovercolor: COLORS.gray.dark,
            hoverbackcolor: COLORS.gray.medium,
            backcolor: COLORS.gray.dark,
        },
        muted: {
            color: COLORS.white,
            backcolor: COLORS.gray.medium,
            hoverbackcolor: COLORS.gray.dark,
        },
        transparent: {
            color: COLORS.gray.dark,
            backcolor: "transparent",
            hoverbackcolor: COLORS.white,
        },
        white: {
            color: COLORS.gray.dark,
            backcolor: COLORS.white,
            hoverbackcolor: COLORS.gray.light,
        },
        attention: {
            color: COLORS.complementary,
            backcolor: "transparent",
            hovercolor: COLORS.gray.medium,
        },
    },

    form: {
        backcolor: COLORS.gray.lightest,
    },

    menu: {
        side: {
            backcolor: "transparent",
            hoverbackcolor: COLORS.gray.lightest,
            submenuborder: COLORS.gray.medium,
            toggle: {
                opened: <FiX />,
                closed: <FiMenu />,
            },
        },
    },

    rating: {
        StronglyAgree: {
            backcolor: "rgba(29, 231, 88, 1)",
        },
        Agree: {
            backcolor: "rgba(29, 231, 88, 0.65)",
        },
        Neutral: {
            backcolor: "rgba(128, 130, 133, 0.25)",
        },
        Disagree: {
            backcolor: "rgba(128, 130, 133, 0.65)",
        },
        StronglyDisagree: {
            backcolor: "rgba(128, 130, 133, 1)",
        },
    },

    notice: {
        error: {
            color: COLORS.white,
            backcolor: COLORS.gray.dark,
            icon: <FiAlertCircle />,
        },
        information: {
            color: COLORS.gray.dark,
            backcolor: COLORS.gray.lightest,
        },
    },
};
