import React from "react";
import styled from "styled-components";
import { LoadingImage } from "er.react.ui";
import { ROOT } from "../services/settings";

const StyledDiv = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 5000;
`;

const FullscreenLoader = () => {
    return (
        <StyledDiv>
            <LoadingImage
                data-process="png"
                image={`${ROOT}/images/nekash.png`}
                alt="نقاش، المنصة التشاركية لدعم اتخاذ القرار"
            />
        </StyledDiv>
    );
};

export default FullscreenLoader;
