import React, { useEffect, Suspense, lazy } from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme/theme";
import { GlobalStyle } from "./theme/global";
import WebFont from "webfontloader";
import { browser } from "er.js.utils";
import FullscreenLoader from "./components/FullscreenLoader";
import ErrorBoundary from "./components/ErrorBoundary";
import GlobalWrapper from "./components/GlobalWrapper";

// https://reactrouter.com/web/guides/quick-start
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

// code splitting: https://reactjs.org/docs/code-splitting.html
// artifical delay to test the suspense loader
//const Authenticator = lazy(() => {
//    return new Promise((resolve) => {
//        setTimeout(() => resolve(import("./features/user/Auhtenticator")), 5000);
//    });
//});

// This will automatically load the bundle containing a Component when this component is first rendered.
const Public = lazy(() => import("./components/Public"));
const ThinkTank = lazy(() => import("./components/ThinkTank"));
const User = lazy(() => import("./features/user/User"));
const Dashboard = lazy(() => import("./components/Dashboard"));

function App() {
    // remove loader when everything is ready
    useEffect(() => {
        const loader = document.getElementById("loader");
        loader.parentElement.removeChild(loader);
    });

    // load the web fonts using webfontloader
    // https://github.com/typekit/webfontloader#google
    useEffect(() => {
        const fontFamily = theme.font.family.arabic;
        const fontWeights = Object.keys(theme.font.weight)
            .map((prop) => theme.font.weight[prop])
            .join(",");

        WebFont.load({
            google: {
                families: [`${fontFamily}:${fontWeights}:arabic,latin`], // added arabic first
            },
        });
    }, []); // [] means that the effect will only run once

    useEffect(() => {
        browser.checkSVG();
    });

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Suspense fallback={<FullscreenLoader />}>
                <BrowserRouter>
                    <ScrollToTop />
                    <ErrorBoundary>
                        <GlobalWrapper>
                            <Switch>
                                <Route path="/" exact>
                                    <ThinkTank />
                                </Route>
                                <Route path="/account">
                                    <Public />
                                </Route>
                                <Route path="/topics">
                                    <ThinkTank />
                                </Route>
                                <Route path="/me">
                                    <User />
                                </Route>
                                <Route path="/dashboard">
                                    <Dashboard />
                                </Route>
                                <Redirect to="/" />
                            </Switch>
                        </GlobalWrapper>
                    </ErrorBoundary>
                </BrowserRouter>
            </Suspense>
        </ThemeProvider>
    );
}

export default App;
