import e,{useState as t,useEffect as o}from"react";import n,{withTheme as r,keyframes as i}from"styled-components";import{Link as a}from"react-router-dom";const l={sm:"600px",md:"769px",lg:"992px",xl:"1200px"},c=e=>{if(l.hasOwnProperty(e))return`@media (min-width: ${l[e]})`};Object.freeze({__proto__:null,media:c}),Object.freeze({__proto__:null,checkSVG:()=>{document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Image","1.1")&&document.querySelectorAll('img[data-process="png"]').forEach((e=>{let t=e.getAttribute("src").replace(".png",".svg");e.setAttribute("src",t),e.removeAttribute("srcset")}))},checkFileUploadDragAndDrop:function(){var e=document.createElement("div");return("draggable"in e||"ondragstart"in e&&"ondrop"in e)&&"FormData"in window&&"FileReader"in window}});const s=(e,t=!0)=>(t&&(e*=1e3),new Date(e));Object.freeze({__proto__:null,convertDateToUnix:(e=null,t=!0)=>(e=e?new Date(e):new Date,t?Math.floor(e.getTime()/1e3):e.getTime()),convertDateFromUnixToDate:s,convertDateFromUnixToString:(e,t=!1,o=!0)=>{let n=o?new Date(1e3*e):new Date(e);return t?n.toISOString().slice(0,10).replace(/-/g,"/"):n.toISOString().slice(0,10)},convertDateFromUnixToLiteralString:(e,t="en-uk",o=!1)=>{var n=s(e);return o?n.toLocaleString(t,{hour12:!0,dateStyle:"short",timeStyle:"short"}):`${n.getDate()} ${n.toLocaleString(t,{month:"long"})} ${n.getFullYear()}`},getCurrentYear:()=>(new Date).getFullYear()});const p=n.div`
    // width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;

    > * {
        margin: ${({gap:e})=>`${e}px 0 ${e}px 0`};
    }

    > :first-child {
        margin-block-start: 0;
    }

    > :last-child {
        margin-block-end: 0;
    }

    ${({splits:e})=>e.split(",").map(((e,t)=>`> :nth-child(${t+1}) { \n                flex-grow: ${e};\n                flex-basis: ${e}%;\n                min-width: 0; // this is added to support text truncate\n            }`))}

    ${c("md")} {
        flex-direction: row;

        > * {
            margin: ${({gap:e})=>`0 ${e}px 0 ${e}px`};
        }

        > :first-child {
            margin-inline-start: 0;
        }

        > :last-child {
            margin-inline-end: 0;
        }
    }
`,d=t=>e.createElement(p,t,t.children);function m(){return(m=Object.assign||function(e){for(var t=1;t<arguments.length;t++){var o=arguments[t];for(var n in o)Object.prototype.hasOwnProperty.call(o,n)&&(e[n]=o[n])}return e}).apply(this,arguments)}const u=n.input`
    padding: 5px;
    border: ${({theme:e,valid:t,required:o})=>o?t?"0":`1px solid ${e.colors.complementary}`:"0"};
    height: ${({type:e})=>"textarea"!==e&&"40px"};
    margin-top: ${({label:e})=>e&&"5px"};
`,g=n.span`
    position: absolute;
    z-index: 40;
    //inset-inline-end: 0;
    top: 0;
    left: 0;
    cursor: pointer;
`,h=n.label`
    position: relative;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
`,b=n.p`
    padding: 2px 5px;
    border-radius: 5px;
    font-size: smaller;
    font-style: italic;
    background-color: ${({theme:e})=>e.colors.complementary};
    color: ${({theme:e})=>e.colors.white};
    margin-top: 5px;
`,x=n.p`
    font-size: smaller;
    margin-top: 5px;
`,f=({label:t,children:o})=>t?e.createElement(h,null,t,o):e.createElement(e.Fragment,null,o),y=({toggle:o,type:n,warning:r,note:i,...a})=>{const[l,c]=t(0),[s,p]=t(n);return e.createElement(f,a,o&&o.length>0&&e.createElement(g,{onClick:()=>{c(l?0:1),p("password"===n&&l?"password":"text")}},o[l]),e.createElement(u,m({type:s},a)),r&&e.createElement(b,null,r),i&&e.createElement(x,null,i))};r(y);const $=n.label`
    display: flex;
    flex-direction: row;

    > span {
        white-space: nowrap;
    }
`,k=n.input`
    width: 20px;
    height: 20px;
    margin: 0 5px;
    flex-shrink: 0;
`,w=t=>e.createElement($,t,e.createElement(k,m({type:"checkbox"},t)),e.createElement("span",null,t.label)),v=n.label`
    // font-size: smaller;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    padding: 7.5px;
    border: ${({theme:e,valid:t,required:o})=>o?t?"0":`2px solid ${e.colors.complementary}`:"0"};
    margin: 5px 0;
    cursor: pointer;
    color: ${({theme:e,disabled:t})=>t?e.button.muted.color:e.button.secondary.color};
    background-color: ${({theme:e,disabled:t})=>t?e.button.muted.backcolor:e.button.secondary.backcolor};

    &:hover:not([disabled]) {
        color: ${({theme:e})=>e.button.secondary.hovercolor};
        background-color: ${({theme:e})=>e.button.secondary.hoverbackcolor};
    }

    &:disabled,
    &[disabled] {
        cursor: not-allowed;
    }

    > * {
        margin-inline-start: 5px;
    }
`,E=n.p`
    font-size: smaller;
    text-align: center;
`,C=t=>e.createElement(e.Fragment,null,e.createElement(v,t,t.label," ",t.icon,t.children),e.createElement(E,null,t.note)),D=n.button`
    transition: ease-in-out 0.2s;
    border: 0;
    cursor: pointer;
    display: flex;
    padding: ${({small:e,icon:t,label:o})=>e?"7.5px 5px;":t&&""===o?"12.5px":"12.5px 20px"};
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: ${({theme:e,format:t="secondary",disabled:o})=>!o&&e.button[t].color};
    background-color: ${({theme:e,format:t="secondary",disabled:o})=>!o&&e.button[t].backcolor};
    box-shadow: none;
    position: relative;
    font-weight: ${({theme:e})=>e.font.weight.medium};

    @media (hover: hover) {
        &:hover:enabled,
        &:hover:not([disabled]) {
            box-shadow: ${({theme:e,format:t="secondary"})=>e.button[t].shadow};
            color: ${({theme:e,format:t="secondary",disabled:o})=>!o&&e.button[t].hovercolor};
            background-color: ${({theme:e,format:t="secondary",disabled:o})=>!o&&e.button[t].hoverbackcolor};
        }
    }

    &:active:enabled {
        top: ${({theme:e,format:t="secondary"})=>e.button[t].shadow&&"1px"};
        box-shadow: none;
    }

    &:disabled,
    &[disabled] {
        cursor: not-allowed;
    }

    > span:first-child {
        order: ${({iconfirst:e})=>e?"2":"1"};
        flex: 0 1 auto;
        vertical-align: middle;
        line-height: 100%;
        // text-align: start;
    }
`,z=n.span`
    margin-inline-start: ${({hasIcon:e,hasLabel:t,iconfirst:o})=>o?"0":t&&e?"5px":"0"};
    margin-inline-end: ${({hasIcon:e,hasLabel:t,iconfirst:o})=>o&&t&&e?"5px":"0"};
    display: flex;
    align-items: center;
    justify-content: center;
    order: ${({iconfirst:e})=>e?"1":"2"};
`,S=t=>e.createElement(D,t,e.createElement("span",null,t.label),e.createElement(z,{hasIcon:t.icon,hasLabel:void 0!==t.label&&""!==t.label,iconfirst:t.iconfirst},t.icon)),F=({confirmlabel:o,confirmicon:n,label:r,icon:i,...a})=>{const[l,c]=t(!1);return l?e.createElement(S,m({},a,{onClick:e=>{a.onClick&&(a.onClick(e),c(!1))},onClickCapture:e=>{a.onClickCapture&&(a.onClickCapture(e),c(!1))},onBlur:()=>{c(!1)},label:o,icon:n})):e.createElement(S,{className:a.className,onClick:e=>{e.stopPropagation(),c(!0)},type:a.type,label:r,icon:i,format:a.format,small:a.small})},_=n.form`
    padding: 15px;
    border-radius: 10px;
    background-color: ${({theme:e})=>e.form.backcolor};

    ${c("md")} {
        padding: 30px;
    }
`,j=n.div`
    margin-top: ${({gap:e})=>e}px;

    &:first-child {
        margin-top: 0;
    }
`,O=t=>e.createElement(_,t,t.children.map(((o,n)=>e.createElement(j,{key:n,gap:t.wrap},o)))),T=n.span`
    > svg {
        animation-name: rotate;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        /* animation-timing-function: cubic-bezier(0.87, 0.025, 0.13, 0.985);*/
        animation-timing-function: linear;
        color: ${({color:e})=>e||"inherit"};
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
`,I=({icon:t,color:o})=>e.createElement(T,{color:o},t),L=n.div`
    height: 4px;
    background-color: ${({theme:e})=>e.loadingbar.backcolor};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: ${({show:e})=>!e&&"none"};
    z-index: 1000;

    &:after {
        content: "";
        position: absolute;
        display: block;
        height: 100%;
        background-color: ${({theme:e})=>e.loadingbar.color};
        left: 0%;
        right: 100%;
        animation: loading 2s ease infinite;
    }

    @keyframes loading {
        0% {
            left: 0;
            right: 100%;
        }

        50% {
            left: 0;
            right: 0;
        }

        100% {
            left: 100%;
            right: 0;
        }
    }
`,A=t=>e.createElement(L,t),U=n.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -100px;
        width: 70px;
        height: 100%;
        background: rgba(255,255,255, 0.3);
        transform: skewX(-30deg);
        animation-name: slide;
        animation-duration: 3s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        // animation-direction: alternate;
        background: linear-gradient(to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.15) 45%,
            rgba(255, 255, 255, 1) 60%,
            rgba(255, 255, 255, 0.15) 75%,
            rgba(255, 255, 255, 0.0) 100%);

        @keyframes slide {
          0% {
            left: -150px;
            top: 0;
          }
          50% {
            left: 120px;
            top: 0px;
          }
          100% {
            left: 150px;
            top: 0;
          }
        }
`,N=n.img`
    flex: 1 1 auto;
    height: 80px;
`,q=({image:t,...o})=>e.createElement(U,null,e.createElement(N,m({src:t},o))),P=n.div`
    padding: 15px;
    position: fixed;
    left: 10px;
    right: 10px;
    bottom: ${({show:e})=>e?"10px":"-80px"};
    border-radius: 5px;
    color: ${({theme:e,type:t="success"})=>e.alert[t].color};
    background-color: ${({theme:e,type:t="success"})=>e.alert[t].backcolor};
    opacity: ${({show:e})=>e?"1":"0"};
    transition: opacity 0.15s, bottom 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    opacity: 0.9;
    cursor: pointer;

    ${c("md")} {
        left: 20px;
        right: 20px;
        bottom: ${({show:e})=>e?"20px":"-60px"};
    }
`,Y=n(S)`
    flex: 0 0 auto;
    height: 30px;
    padding: 10px;
    opacity: 0.75;
    background-color: ${({theme:e})=>e.colors.white};
    color: ${({color:e})=>e};
`,G=n.p`
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        margin-inline-end: 10px;
        margin-inline-start: 0;
    }
`,R=({theme:n,...r})=>{const i=n.alert[r.type].icon,[a,l]=t(!1);return o((()=>{l(r.show)}),[r.show]),e.createElement(P,m({},r,{show:r.onClick?r.show:a,onClick:r.onClick?r.onClick:()=>{l(!1)}}),e.createElement(G,null,i,r.text),e.createElement(Y,{color:n.alert[r.type].backcolor,icon:n.alert.icon,type:"button",format:"basic"}))};r(R);const V=n.div`
    display: flex;
    flex-direction: column;
`,B=n.button`
    flex: 1 1 auto;
    border: 0;
    background-color: transparent;
    display: block;
    padding: 10px;
    font-size: larger;
    color: ${({theme:e,type:t})=>e.menu[t].toggle.color};
    background-color: ${({theme:e,type:t})=>e.menu[t].toggle.backcolor};

    ${c("md")} {
        display: none;
    }
`,M=n.ul`
    transition: ease-in-out 0.25s;
    list-style: none;
    max-height: ${({opened:e})=>e?"1200px":"0px"};
    height: auto;
    overflow: hidden;

    ${c("md")} {
        max-height: ${({opened:e,sub:t})=>t?e?"1200px":"0px":"1200px"};
    }
`,X=n.li`
    transition: ease-in-out 0.25s;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    > a,
    p {
        color: ${({theme:e,type:t})=>e.menu[t].color};
        background-color: ${({theme:e,type:t,active:o})=>o?e.menu[t].hoverbackcolor:e.menu[t].backcolor};

        @media (hover: hover) {
            &:hover {
                color: ${({theme:e,type:t})=>e.menu[t].hovercolor};
                background-color: ${({theme:e,type:t})=>e.menu[t].hoverbackcolor};
            }
        }

        font-weight: ${({theme:e,opened:t})=>t&&e.font.weight.bold};
        padding: 15px 10px;
        flex: 1 1 auto;

        ${c("md")} {
            padding: 10px;
        }
    }
`,H=n(M)`
    // padding-inline-start: 10px;
    margin-inline-start: 10px;
    border-top: ${({theme:e,type:t,opened:o})=>o&&`1px solid ${e.menu[t].submenuborder}`};
    border-bottom: ${({theme:e,type:t,opened:o})=>o&&`1px solid ${e.menu[t].submenuborder}`};
    font-size: smaller;
`,J=({theme:o,...n})=>{const[r,i]=t(!1),[l,c]=t(null);return e.createElement(V,{className:n.className},e.createElement(B,{type:n.type,onClick:()=>i(!r)},r?o.menu[n.type].toggle.opened:o.menu[n.type].toggle.closed),e.createElement(M,{type:n.type,opened:r},n.items.map(((t,o)=>e.createElement(X,{type:n.type,key:`item${o}`,active:t.active,opened:l===o},t.links?e.createElement(e.Fragment,null,e.createElement("p",{onClick:()=>{c(null!==l&&l===o?null:o)}},t.title),e.createElement(H,{type:n.type,opened:l===o,sub:!0},t.links.map(((t,o)=>e.createElement(X,{type:n.type,key:`item${o}`,active:t.active,onClick:()=>i(!r)},e.createElement(a,{to:t.link},t.title)))))):e.createElement(a,{onClick:()=>{i(!r),c(null)},to:t.link},t.title))))))};r(J);const K=i`
            0% {
                background-position: 50% 0%;
            }

            50% {
                background-position: 0% 50%;
            }

            100% {
                background-position: 50% 0%;
            }
`,Q=n.p`
    border-radius: 5px;
    background-image: ${({theme:e})=>`linear-gradient(45deg, ${e.colors.white}, ${e.colors.gray.medium} 50%, ${e.colors.white})`};
    background-size: 400% 400%;
    animation-name: ${K};
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    max-width: ${({width:e})=>e&&e}px;
    width: auto;
    height: ${({height:e})=>e&&e}px;
`,W=t=>e.createElement(Q,t);export{R as Alert,W as Bone,S as Button,F as ButtonDual,w as Checkbox,C as FileUpload,O as Form,y as Input,A as LoadingBar,I as LoadingIcon,q as LoadingImage,J as Menu,d as Split};
