export const loadLocalStorage = (itemKey) => {
    try {
        const serializedState = localStorage.getItem(itemKey);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (error) {
        return undefined;
    }
};

export const saveLocalStorage = (itemKey, itemValue) => {
    try {
        const serializedState = JSON.stringify(itemValue);
        localStorage.setItem(itemKey, serializedState);
    } catch (error) {
        console.log("An error occured when serializing object.");
    }
};

export const deleteLocalStorage = (itemKey) => {
    try {
        localStorage.removeItem(itemKey);
    } catch (error) {}
}