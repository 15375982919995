import { createSlice } from "@reduxjs/toolkit";
import { TOPICS_FILTER } from "../../services/settings";

const initialState = {
    filter: {
        order: TOPICS_FILTER.ByLatestPost,
        space: null,
    },
};

const topicsSlice = createSlice({
    name: "topics",
    initialState,
    reducers: {
        topicsOrderFilterChanged(state, action) {
            state.filter.order = action.payload;
        },
        topicsSpaceFilterChanged(state, action) {
            state.filter.space = action.payload;
        },
    },
});

export const selectTopicsOrderFilter = (state) => state.topics.filter.order;
export const selectTopicsSpaceFilter = (state) => state.topics.filter.space;

export const { topicsOrderFilterChanged, topicsSpaceFilterChanged } = topicsSlice.actions;

export default topicsSlice.reducer;
