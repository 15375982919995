import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./settings";
import { userLoggedOut } from "../features/user/userSlice";
import { updateUserState } from "../services/utils";

const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    // https://redux-toolkit.js.org/rtk-query/api/fetchBaseQuery#setting-default-headers-on-requests
    prepareHeaders: (headers, { getState }) => {
        const token = getState().user.token;
        // if we have a valid token, add it to the authentication header
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
// this is a wrapper for fetchBaseQuery function
// when any request receives a 401 response, an additional request is sent to attempt to refresh the access token
// after that the initial request will be reinitialized
export const baseQueryWithRefreshTokenAttempt = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        const token = api.getState().user.token;
        if (token) {
            // try to get a new token
            const refreshResult = await baseQuery(
                {
                    url: "token/refresh",
                    method: "POST",
                    credentials: "include",
                    body: { token },
                },
                api,
                extraOptions
            );

            if (refreshResult.data) {
                // store the new token and claims
                updateUserState(refreshResult.data);
                // retry the initial query
                result = await baseQuery(args, api, extraOptions);
            } else {
                api.dispatch(userLoggedOut());
            }
        }
    }
    return result;
};
