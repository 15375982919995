import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { theme } from "../../theme/theme";

const initialState = {
    loading: false,
    alert: {
        timeout: null,
        type: "error",
        show: false,
        // color: theme.colors.white,
        // backcolor: theme.colors.complementary,
        text: "",
    },
};

// https://stackoverflow.com/questions/64850725/reactjs-and-redux-toolkit-can-i-use-createasyncthunk-to-execute-non-async-s
export const hideAlert = () => (dispatch, getState) => {
    // clear the timeout if it exists
    const timeout = getState().notifications.alert.timeout;
    timeout && clearTimeout(timeout);

    dispatch(
        alertUpdated({
            show: false,
        })
    );
};

// https://github.com/reduxjs/redux-toolkit/issues/773
// https://redux-toolkit.js.org/api/createAsyncThunk#payloadcreator
export const showAlert = createAsyncThunk(
    "notifications/showAlert",
    async (payload, { dispatch, getState } /* instead of thunkAPI*/) => {
        // hide alert after 5 seconds
        const response = await new Promise((resolve) => {
            var timeout = getState().notifications.alert.timeout;

            // clear the timeout if it exists
            timeout && clearTimeout(timeout);

            // set a new timeout
            timeout = setTimeout(() => resolve(), 5000);

            // show alert
            dispatch(
                alertUpdated({
                    ...payload,
                    timeout,
                })
            );
        });

        // return fulfilled/rejected
        return response;
    }
);

const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        loadingUpdated(state, action) {
            state.loading = action.payload;
        },
        alertUpdated(state, action) {
            state.alert = {
                ...state.alert,
                ...action.payload,
            };

            // extra option to prepare the received payload before updating state
            // https://redux.js.org/tutorials/essentials/part-4-using-data#preparing-action-payloads
        },
    },
    extraReducers: (builder) => {
        builder.addCase(showAlert.fulfilled, (state) => {
            state.alert.show = false;
        });
    },
});

// selectors
export const selectLoader = (state) => state.notifications.loading;
export const selectAlert = (state) => state.notifications.alert;

// export actions
export const { loadingUpdated, alertUpdated } = notificationsSlice.actions;

export default notificationsSlice.reducer;
