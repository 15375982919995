import { userAuthenticated, userLoggedOut } from "../features/user/userSlice";
import { saveLocalStorage } from "./localStorage";
import {
    isAsyncThunkAction,
    isPending,
    isFulfilled,
    isRejected,
    isRejectedWithValue,
} from "@reduxjs/toolkit";
import { loadingUpdated, showAlert } from "../features/notifications/notificationsSlice";

// https://stackoverflow.com/questions/68421040/local-storage-using-redux-toolkit
// https://redux.js.org/understanding/history-and-design/middleware
export const userMiddleware = (store) => (next) => (action) => {
    // https://redux.js.org/understanding/history-and-design/middleware#seven-examples
    let result = next(action);

    // update presisted state if the user logged in
    if (userAuthenticated.match(action)) {
        saveLocalStorage("state", {
            user: store.getState().user,
        });
    }

    // clear the local storage if the user logged out
    if (userLoggedOut.match(action)) {
        localStorage.clear();
    }

    return result;
};

// Thunks/Actions that should not show a loader
// https://redux-toolkit.js.org/api/matching-utilities#isasyncthunkaction
const isSilentAction = isAsyncThunkAction(showAlert);
// centralized place to show/hide loaders when a no-slient api request is made
// https://redux-toolkit.js.org/rtk-query/usage/error-handling
export const genericLoaderMiddleware = (store /*api*/) => (next) => (action) => {
    if (!isSilentAction(action)) {
        if (isPending(action)) {
            store.dispatch(loadingUpdated(true));
        }
        if (isFulfilled(action) || isRejected(action) || isRejectedWithValue(action)) {
            store.dispatch(loadingUpdated(false));
        }
    }

    return next(action);
};

//// https://redux-toolkit.js.org/rtk-query/usage/error-handling#handling-errors-at-a-macro-level
//export const errorMiddleware = (store) => (next) => (action) => {
//    if (isRejected(action) && action.payload) {
//        console.log(action);
//        console.log(action.payload);
//    }

//    return next(action);
//};
