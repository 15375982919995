import React from "react";
import { withTheme } from "styled-components";
import { LoadingBar, Alert } from "er.react.ui";
import { useSelector, useDispatch } from "react-redux";
import { selectLoader, selectAlert } from "../features/notifications/notificationsSlice";
import { hideAlert } from "../features/notifications/notificationsSlice";

const GlobalWrapper = ({ theme, children }) => {
    const dispatch = useDispatch();

    const loading = useSelector(selectLoader);
    const alert = useSelector(selectAlert);

    return (
        <>
            <LoadingBar show={loading} />
            <Alert
                {...alert}
                theme={theme}
                onClick={() => {
                    dispatch(hideAlert());
                }}
            />
            {children}
        </>
    );
};

export default withTheme(GlobalWrapper);
