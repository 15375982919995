// entity name
export const ENTITY = "HCSR";

// root URL of the client
export const ROOT = process.env.PUBLIC_URL;

// root URL of the API
//export const ROOT_URL =
//    process.env.NODE_ENV === "production" ? "https://localhost:44302" : "https://localhost:44302";

// root URL of the API
export const ROOT_URL =
    process.env.NODE_ENV === "production" ? "https://www.nekash.com" : "https://localhost:44302";

// api url
export const API_URL = `${ROOT_URL}/api/`;

// temp url
export const TEMP_URL = `${API_URL}attachments/${ENTITY}/temp/`;

// errors reporting url
export const ERROR_URL = `${API_URL}errors/report`;

// user claim types (for authentication)
export const CLAIM_TYPES = {
    Admin: "ManagerSystem",
    Manager: `Manager${ENTITY}`,
    Expert: `Expert${ENTITY}`,
    Observer: `Observer${ENTITY}`,
};

// user claim types (for adding users)
export const ADD_USER_CLAIM_TYPES = [
    { title: "مدير نظام", value: "Manager,System" },
    { title: "رئيس هيئة", value: `Manager,${ENTITY}` },
    { title: "خبير", value: `Expert,${ENTITY}` },
    { title: "مراقب", value: `Observer,${ENTITY}` },
];

// user claim types (for list)
export const CLAIM_TYPES_NEUTRAL = [
    { title: "مدير نظام", value: "ManagerSystem" },
    { title: "رئيس هيئة", value: "Manager" },
    { title: "خبير", value: "Expert" },
    { title: "مراقب", value: "Observer" },
];

// allowed characters in username
export const USERNAME_ALLOWED_CHARS =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._@+".split("");

// users titles
export const USERS_TITLES = [
    "الآنسة",
    "السيدة",
    "السيد",
    "الأستاذة",
    "الأستاذ",
    "الدكتورة",
    "الدكتور",
    "المهندسة",
    "المهندس",
    "القاضية",
    "القاضي",
    "المحامية",
    "المحامي",
    "المستشارة",
    "المستشار",
];

// https://www.iana.org/assignments/media-types/media-types.xhtml
// allowed images and files formats
export const ALLOWED_IMAGE_FORMATS = "image/jpeg, image/png, image/bmp, image/tiff";
export const ALLOWED_FILE_FORMATS = ".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .dwg, .zip, .rar";

export const ATTACHMENT_TYPE = {
    image: 1,
    file: 2,
    video: 3,
};

export const TOPICS_FILTER = {
    ByLatestPost: 1,
    ByUpvotes: 2,
    ByHasActiveProposal: 3,
    ByUnread: 4,
};

export const VOTING = {
    StronglyAgree: {
        title: "موافق بشدّة",
        value: 5,
    },
    Agree: {
        title: "موافق",
        value: 4,
    },
    Neutral: {
        title: "حيادي",
        value: 3,
    },
    Disagree: {
        title: "معارض",
        value: 2,
    },
    StronglyDisagree: {
        title: "معارض بشدّة",
        value: 1,
    },
};
