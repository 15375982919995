import { configureStore } from "@reduxjs/toolkit";
// import counterReducer from "../features/counter/counterSlice";
import userReducer from "../features/user/userSlice";
import notificationsReducer from "../features/notifications/notificationsSlice";
import topicsReducer from "../features/topics/topicsSlice";
import { api } from "../services/api";
import { setupListeners } from "@reduxjs/toolkit/query";
import { loadLocalStorage /*saveState*/ } from "../services/localStorage";
import {
    genericLoaderMiddleware,
    userMiddleware /*, errorMiddleware*/,
} from "../services/middleware";

const presistedState = loadLocalStorage("state");

// https://redux-toolkit.js.org/api/configureStore
export const store = configureStore({
    reducer: {
        // counter: counterReducer,
        user: userReducer,
        notifications: notificationsReducer,
        topics: topicsReducer,
        [api.reducerPath]: api.reducer,
    },
    devTools: process.env.NODE_ENV === "production" ? false : true,
    preloadedState: presistedState,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(api.middleware)
            .concat(genericLoaderMiddleware)
            .concat(userMiddleware),
    // .concat(errorMiddleware),
});

// https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage
// Used middleware based on the action type instead
//store.subscribe(() => {
//    saveState({
//        user: store.getState().user,
//    });
//});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
