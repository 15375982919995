import React from "react";
import styled from "styled-components";
import { reportError } from "../services/utils";
import Logo from "./Logo";

const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 100px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
`;

const StyledLogo = styled(Logo)`
    margin-top: 20px;
`;

export default class ErrorBoundary extends React.Component {
    // https://reactjs.org/docs/error-boundaries.html

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorMessage: "",
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        reportError(error, errorInfo);
        // console.log(error.message);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Container>
                    <h1>حصل خطأ في الاتصال بالمخدم، الرجاء إعادة تحميل الصفحة.</h1>
                    <p>يمكن أن يكون ذلك بسبب عطل في الاتصال بالانترنت أو خطأ ما في المخدم.</p>
                    <StyledLogo height={{ md: 80, sm: 60 }} />
                </Container>
            );
        }

        return this.props.children;
    }
}
