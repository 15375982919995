import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// https://reactrouter.com/web/guides/scroll-restoration

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });
    }, [pathname]);

    return null;
}
