import React from "react";
import { ROOT } from "../services/settings";
import { media } from "er.js.utils";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledImage = styled.img`
    transition: ease-in-out 0.2s;
    display: block;
    width: auto;
    height: ${({ height }) => (height.sm ? height.sm : height.md ?? height)}px;

    ${media("md")} {
        height: ${({ height }) => (height.md ? height.md : height)}px;
    }
`;

const Beta = styled.span`
    font-size: ${({ theme }) => theme.font.size.xxs};
    display: block;
    margin: 0 auto;
    color: ${({ theme }) => theme.colors.gray.dark};
    background-color: ${({ theme }) => theme.colors.gray.light};
    border-radius: 2px;
    padding: 1px 5px;
    margin-top: 5px;
    text-align: center;
`;

const Logo = (props) => {
    return (
        <Link to="/">
            <StyledImage
                {...props}
                data-process="png"
                srcSet={`${ROOT}/images/nekash@1.5x.png 1.5x, ${ROOT}/images/nekash@2x.png 2x, ${ROOT}/images/nekash@3x.png 3x`}
                src={`${ROOT}/images/nekash.png`}
                alt="نقاش، المنصة التشاركية لدعم اتخاذ القرار"
            />
            <Beta>نسخة تجريبية</Beta>
        </Link>
    );
};

export default Logo;
